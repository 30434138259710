import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
    routes: [
        {
            path: '/saveUserinfo',
            name: 'saveUserinfo',
            component: () => import('@/views/newPayUserinfo'),
            meta: {
                title: '保存用户信息'    //  公众号银行卡支付
            }
        },
        {
            path: '/paypalcard',
            name: 'paypalcard',
            component: () => import('@/views/paypalcard/officialAccount.vue'),
            meta: {
                title: '银行卡支付'    //  公众号银行卡支付
            }
        },

        {
            path: '/paypalcard6',
            name: 'paypalcard6',
            component: () => import('@/views/paypalcard/officialAccount6.vue'),
            meta: {
                title: '银行卡支付'    //  公众号银行卡支付
            }
        },
        {
            path: '/alipaytest',
            name: 'alipaytest',
            component: () => import('@/views/alipay.vue'),
            meta: {
                title: 'alipayTest'
            }
        },
        {
            path: '/credit',
            name: 'Credit',
            component: () => import('@/views/credit/index.vue'),
            meta: {
                title: '游戏充值'
            }
        },

        {
            path: '/darkxiyouxcx',
            name: 'Darkxiyouxcx',
            component: () => import('@/views/darkxiyouxcx/index.vue'),
            meta: {
                title: '游戏充值'
            }
        },
        {
            path: '/credit/pay',
            name: 'Pay',
            component: () => import('@/views/credit/pay.vue'),
            meta: {
                title: '支付'
            }
        },
        {
            path: '/gold/pay',
            name: 'Pay',
            component: () => import('@/views/gold/pay.vue'),
            meta: {
                title: '打金传说支付'
            }
        },
        {
            path: '/fenghuo/pay',
            name: 'Pay',
            component: () => import('@/views/fhpayment/pay.vue'),
            meta: {
                title: '支付'
            }
        },
        {
            path: '/ghlcity/pay',
            name: 'Pay',
            component: () => import('@/views/newPayGhlcity/pay.vue'),
            meta: {
                title: '支付'
            }
        },
        {
            path: '/dkxyxcxh5/pay',
            name: 'Pay',
            component: () => import('@/views/xcxh5/darkxiyou/pay.vue'),
            meta: {
                title: '支付'
            }
        },
        {
            path: '/darkxiyouxcx/pay',
            name: 'Pay',
            component: () => import('@/views/darkxiyouxcx/pay.vue'),
            meta: {
                title: '支付'
            }
        },
        {
            path: '/fkjlb/pay',
            name: 'Pay',
            component: () => import('@/views/fkjlb/pay.vue'),
            meta: {
                title: '支付'
            }
        },
        {
            path: '/secret',
            name: 'Secret',
            component: () => import('@/views/newPaySecret/index.vue'),
            meta: {
                title: '大陆充值'
            }
        },
        {
            path: '/role',
            name: 'Role',
            component: () => import('@/views/newPaySecret/role.vue'),
            meta: {
                title: 'VIP通道'
            }
        },
        {
            path: '/vip',
            name: 'Vip',
            component: () => import('@/views/vip/vip.vue'),
            meta: {
                title: 'VIP通道'
            }
        },
        {
            path: '/nuhuo',
            name: 'NuHuo',
            component: () => import('@/views/nuhuo/index.vue'),
            meta: {
                title: '大陆充值'
            }
        },
        {
            path: '/dajin',
            name: 'Dajin',
            component: () => import('@/views/newPayDajin/index.vue'),
            meta: {
                title: '充值中心'//打金传说
            }
        },
        {
            path: '/fhpayment',
            name: 'Fhpayment',
            component: () => import('@/views/fhpayment/paymentauto.vue'),
            meta: {
                title: '充值'//烽火龙城自定义充值
            },
            props: route => ({ query: route.query.order })
        },
        {
            path: '/fhMiniPayment',
            name: 'FhMiniPayment',
            component: () => import('@/views/fhpayment/miniProPay.vue'),
            meta: {
                title: '充值'//烽火龙城小程序微信支付
            },
            props: route => ({ query: route.query.order })
        },
        {
            path: '/fhOfficialAccountPayment',
            name: 'FhOfPayment',
            component: () => import('@/views/fhpayment/paymentOfficialAccount.vue'),
            meta: {
                title: '充值'//烽火龙城公众号自定义充值
            }
        },
        {
            path: '/fhkf',
            name: 'Fhkf',
            component: () => import('@/views/fhpayment/customerService.vue'),
            meta: {
                title: '烽火龙城'//烽火龙城客服
            }
        },
        {
            path: '/fhOfficialAccount',
            name: 'FhOfficialAccount',
            component: () => import('@/views/fhpayment/fhOfficialAccount.vue'),
            meta: {
                title: '烽火龙城'//烽火龙城公众号客服
            }
        },
        {
            path: '/gobackMiniGame',
            name: 'GobackMiniGame',
            component: () => import('@/views/fhpayment/gobackMiniGame.vue'),
            meta: {
                title: '烽火龙城'//烽火龙城--小程序返回小游戏
            }
        },
        {
            path: '/gold/mid',
            name: 'GoldMid',
            component: () => import('@/views/gold/mid.vue'),
            meta: {
                title: '支付跳转'
            }
        },
        {
            path: '/fenghuo/mid',
            name: 'FenghuoMid',
            component: () => import('@/views/fhpayment/mid.vue'),
            meta: {
                title: '支付跳转'
            }
        },
        {
            path: '/credit/mid',
            name: 'PayMid',
            component: () => import('@/views/credit/mid.vue'),
            meta: {
                title: '支付跳转'
            }
        },

        {
            path: '/darkxiyouxcx/mid',
            name: 'PayMid',
            component: () => import('@/views/darkxiyouxcx/mid.vue'),
            meta: {
                title: '支付跳转'
            }
        },
        {
            path: '/fkjlb/mid',
            name: 'PayMid',
            component: () => import('@/views/fkjlb/mid.vue'),
            meta: {
                title: '支付跳转'
            }
        },
        {
            path: '/download/mfms',
            name: 'Mfms',
            component: () => import('@/views/download/mfms.vue'),
            meta: {
                title: '玛法灭世下载'
            }
        },
        {
            path: '/download/xasc',
            name: 'Xasc',
            component: () => import('@/views/download/xasc.vue'),
            meta: {
                title: '笑傲沙城下载'
            }
        },
        {
            path: '/download/xytl',
            name: 'Xytl',
            component: () => import('@/views/download/xytl.vue'),
            meta: {
                title: '血影屠龙下载'
            }
        },
        {
            path: '/download/nhyh',
            name: 'Nhyh',
            component: () => import('@/views/download/nhyh.vue'),
            meta: {
                title: '怒火英豪下载'
            }
        },
        {
            path: '/gameGiftpack',
            name: 'GameGiftpack',
            component: () => import('@/views/gameGiftpack/index.vue'),
            meta: {
                title: '游戏礼包'
            }
        },
        {
            path: '/gift/dajin',
            name: 'GiftDaJin',
            component: () => import('@/views/gift/dajin.vue'),
            meta: {
                title: '游戏礼包'
            }
        },
        {
            path: '/gift/darkxiyou',
            name: 'GiftDaJin',
            component: () => import('@/views/gift/darkxiyou.vue'),
            meta: {
                title: '游戏礼包'
            }
        },
        {
            path: '/gift/darkxiyouApplet',
            name: 'GiftXiyouApplet',
            component: () => import('@/views/gift/darkxiyouApplet.vue'),
            meta: {
                title: '游戏礼包'
            }
        },
        {
            path: '/gift/ghlc',
            name: 'GiftCity',
            component: () => import('@/views/gift/ghlc.vue'),
            meta: {
                title: '游戏礼包'
            }
        },
        {
            path: '/gift/fhlc',
            name: 'GiftFhlc',
            component: () => import('@/views/gift/fhlc.vue'),
            meta: {
                title: '游戏礼包'
            }
        },
        {
            path: '/gift/rxhj',
            name: 'GiftCity',
            component: () => import('@/views/gift/rxhj.vue'),
            meta: {
                title: '游戏礼包'
            }
        },
        {
            path: '/gift/sgtl',
            name: 'GiftSgtl',
            component: () => import('@/views/gift/sgtl.vue'),
            meta: {
                title: '游戏礼包'
            }
        },
        {
            path: '/gift/fkjlb',
            name: 'GiftCity',
            component: () => import('@/views/gift/fkjlb.vue'),
            meta: {
                title: '游戏礼包'
            }
        },
        {
            path: '/gift/dscj',
            name: 'GiftDscjCity',
            component: () => import('@/views/gift/dscj.vue'),
            meta: {
                title: '游戏礼包'
            }
        },
        {
            path: '/mrt',
            name: 'Mrt',
            component: () => import('@/views/mrt/index.vue'),
            meta: {
                title: '《玛法灭世》荣誉名人堂'
            }
        },
        {
            path: '/page',
            name: 'Page',
            component: () => import('@/views/utils/page.vue'),
            meta: {
                title: '请使用浏览器打开'
            }
        },
        {
            path: '/redirect',
            name: 'Redirect',
            component: () => import('@/views/utils/redirect.vue'),
            meta: {
                title: '请使用浏览器打开'
            }
        },
        {
            path: '/activity/mrt',
            name: 'MrtTest',
            component: () => import('@/views/activity/mrt.vue'),
            meta: {
                title: '《玛法灭世》荣誉名人堂'
            }
        },
        {
            path: '/activity/redpacket',
            name: 'Redpacket',
            component: () => import('@/views/activity/redpacket.vue'),
            meta: {
                title: '红人榜'
            }
        },
        {
            path: '/activity/popularity',
            name: 'Popularity',
            component: () => import('@/views/activity/popularity.vue'),
            meta: {
                title: '人气榜'
            }
        },
        {
            path: '/activity/drawCouponPre',
            name: 'DrawCouponPre',
            component: () => import('@/views/activity/drawCouponPre.vue'),
            meta: {
                title: '抽代金券'
            }
        },
        {
            path: '/activity/drawCoupon',
            name: 'DrawCoupon',
            component: () => import('@/views/activity/drawCouponDev.vue'),
            meta: {
                title: '抽代金券'
            }
        },
        {
            path: '/activity/collect',
            name: 'ActivityCollect',
            component: () => import('@/views/activity/collect.vue'),
            meta: {
                title: '投票活动'
            }
        },
        {
            path: '/activity/collectTest',
            name: 'ActivityCollectPre',
            component: () => import('@/views/activity/collectPre.vue'),
            meta: {
                title: '投票活动测试'
            }
        },
        {
            path: '/sp',
            name: 'summerParty',
            component: () => import('@/views/activity/summerParty.vue'),
            meta: {
                title: '夏日派对'
            }
        },
        {
            path: '/user',
            name: 'User',
            component: () => import('@/views/user/index.vue'),
            meta: {
                title: '用户中心'
            }
        },
        {
            path: '/icelord',
            name: 'Icelord',
            component: () => import('@/views/newPayIcelord/index.vue'),
            meta: {
                title: '充值'//冰城主宰自定义充值
            },
            props: route => ({ query: route.query.order })
        }, {
            path: '/icelord/mid',
            name: 'IcelordMid',
            component: () => import('@/views/newPayIcelord/mid.vue'),
            meta: {
                title: '充值'//冰城主宰自定义充值
            },
            props: route => ({ query: route.query.order })
        },
        {
            path: '/cn/rxdld',
            name: 'CnRxdld',
            component: () => import('@/views/newPayRxdld/index.vue'),
            meta: {
                title: '充值中心'//热血大乱斗中文
            }
        },
        {
            path: '/eg/rxdld',
            name: 'EgRxdld',
            component: () => import('@/views/newPayEgrxdld/index.vue'),
            meta: {
                title: 'Recharge Center'//热血大乱斗英文
            }
        },
        {
            path: '/rxdldH5',
            name: 'RxdldH5',
            component: () => import('@/views/newPayRxdldH5/index.vue'),
            meta: {
                title: 'Recharge Center'//热血大乱斗H5内嵌
            }
        },
        {
            path: '/ghlcity',
            name: 'Ghlcity',
            component: () => import('@/views/newPayGhlcity/index.vue'),
            meta: {
                title: '光辉龙城充值'//光辉龙城自定义充值
            },
            props: route => ({ query: route.query.order })
        }, {
            path: '/ghlcity/mid',
            name: 'GhlcityMid',
            component: () => import('@/views/newPayGhlcity/mid.vue'),
            meta: {
                title: '充值'//光辉龙城自定义充值
            },
            props: route => ({ query: route.query.order })
        }, {
            path: '/rxhj',
            name: 'Rxhj',
            component: () => import('@/views/newPayRxhj/index.vue'),
            meta: {
                title: '热血合击充值'//光辉龙城自定义充值
            },
            props: route => ({ query: route.query.order })
        }, {
            path: '/sgtl',
            name: 'Sgtl',
            component: () => import('@/views/newPaySgtl/index.vue'),
            meta: {
                title: '三国屠龙充值'
            },
            props: route => ({ query: route.query.order })
        }, {
            path: '/sgtl/mid',
            name: 'SgtlMid',
            component: () => import('@/views/newPaySgtl/mid.vue'),
            meta: {
                title: '充值'
            },
            props: route => ({ query: route.query.order })
        },
        {
            path: '/sgtl/pay',
            name: 'SgtlPay',
            component: () => import('@/views/newPaySgtl/pay.vue'),
            meta: {
                title: '三国屠龙充值'
            },
            props: route => ({ query: route.query.order })
        }, {
            path: '/smzb',
            name: 'Smzb',
            component: () => import('@/views/newPaySmzb/index.vue'),
            meta: {
                title: '神魔争霸充值'
            },
            props: route => ({ query: route.query.order })
        }, {
            path: '/smzb/mid',
            name: 'SmzbMid',
            component: () => import('@/views/newPaySmzb/mid.vue'),
            meta: {
                title: '充值'
            },
            props: route => ({ query: route.query.order })
        },
        {
            path: '/smzb/pay',
            name: 'SmzbPay',
            component: () => import('@/views/newPaySmzb/pay.vue'),
            meta: {
                title: '神魔争霸充值'
            },
            props: route => ({ query: route.query.order })
        }, {
            path: '/payGame',
            name: 'PayGame',
            component: () => import('@/views/payGame/index.vue'),
            meta: {
                title: '充值'
            },
            props: route => ({ query: route.query.order })
        }, {
            path: '/paySuccess',
            name: 'PaySuccess',
            component: () => import('@/views/payGame/success.vue'),
            meta: {
                title: '充值'
            },
            props: route => ({ query: route.query.order })
        }, {
            path: '/payAppSuccess',
            name: 'PayAppSuccess',
            component: () => import('@/views/payGame/payAppSuccess.vue'),
            meta: {
                title: '充值'
            },
            props: route => ({ query: route.query.order })
        }, {
            path: '/payAppFail',
            name: 'PayAppFail',
            component: () => import('@/views/payGame/payAppFail.vue'),
            meta: {
                title: '充值'
            },
            props: route => ({ query: route.query.order })
        }, {
            path: '/rxhj/mid',
            name: 'RxhjMid',
            component: () => import('@/views/newPayRxhj/mid.vue'),
            meta: {
                title: '充值'//光辉龙城自定义充值
            },
            props: route => ({ query: route.query.order })
        },
        {
            path: '/rxhj/pay',
            name: 'RxhjPay',
            component: () => import('@/views/newPayRxhj/pay.vue'),
            meta: {
                title: '热血合击充值'//热血充值
            },
            props: route => ({ query: route.query.order })
        },
        {
            path: '/stp_notice_page',
            name: 'StripeNoticePage',
            component: () => import('@/views/stripeNotice/index.vue'),
            meta: {
                title: '充值'
            }
        },
        {
            path: '/andro_ios_paypal_page',
            name: 'androIosPaypalPage',
            component: () => import('@/views/stripeNotice/android_ios_pay.vue'),
            meta: {
                title: '充值'
            }
        },
        {
            path: '/success_page',
            name: 'SuccessPage',
            component: () => import('@/views/stripeNotice/success_pay.vue'),
            meta: {
                title: '充值'
            }
        },
        {
            path: '/error_page',
            name: 'ErrorPage',
            component: () => import('@/views/stripeNotice/error_pay.vue'),
            meta: {
                title: '充值'
            }
        },
        {
            path: '/success',
            name: 'LianlianSuccess',
            component: () => import('@/views/stripeNotice/success.vue'),
            meta: {
                title: '充值'
            }
        },
        {
            path: '/androd_h5_pay',
            name: 'androdH5Pay',
            component: () => import('@/views/commonPayPage/androdH5Pay.vue'),
            meta: {
                title: '充值'
            }
        },
        {
            path: '/androd_h5_pay_us',
            name: 'androdH5PayUS',
            component: () => import('@/views/commonPayPage/androdH5PayUS.vue'),
            meta: {
                title: '充值'//英文版安卓充值
            }
        },
        {
            path: '/deluser',
            name: 'androdDelUser',
            component: () => import('@/views/user/dell.vue'),
            meta: {
                title: '用户删除页面'
            }
        },
        {
            path: '/delusers',
            name: 'androdDelUserTwo',
            component: () => import('@/views/user/dellusers.vue'),
            meta: {
                title: '用户删除页面'
            }
        },
        {
            path: '/deluser/rxdld/login',
            name: 'androdDelUser',
            component: () => import('@/views/dellUser/rxdld/login.vue'),
            meta: {
                title: '用户删除页面'
            }
        },
        {
            path: '/deluser/rxdld/tips',
            name: 'androdDelUser',
            component: () => import('@/views/dellUser/rxdld/dellTips.vue'),
            meta: {
                title: '用户删除页面'
            }
        },
        {
            path: '/deluser/rxdld/success',
            name: 'androdDelUser',
            component: () => import('@/views/dellUser/rxdld/success.vue'),
            meta: {
                title: '用户删除页面'
            }
        },
        {
            path: '/deluser/gzcs/login',
            name: 'androdDelUser',
            component: () => import('@/views/dellUser/gzcs/login.vue'),
            meta: {
                title: '国战传说注销'
            }
        },
        {
            path: '/deluser/gzcs/cancel',
            name: 'androdDelUser',
            component: () => import('@/views/dellUser/gzcs/cancel.vue'),
            meta: {
                title: '国战传说注销'
            }
        },
        {
            path: '/deluser/gzcs/confirm',
            name: 'androdDelUser',
            component: () => import('@/views/dellUser/gzcs/confirm.vue'),
            meta: {
                title: '国战传说注销'
            }
        },
        {
            path: '/deluser/gzcs/successful',
            name: 'androdDelUser',
            component: () => import('@/views/dellUser/gzcs/successful.vue'),
            meta: {
                title: '国战传说注销'
            }
        },
        {
            path: '/androdH5Mid',
            name: 'androdH5Mid',
            component: () => import('@/views/commonPayPage/androdH5Mid.vue'),
            meta: {
                title: '微信充值'
            }
        },
        {
            path: '/win_pay_info',
            name: 'winPayPage',
            component: () => import('@/views/winPayPage/index.vue'),
            meta: {
                title: '信用卡支付'
            }
        },
        {
            path: '/darkxiyou',
            name: 'Darkxiyou',
            component: () => import('@/views/newPayDarkxiyou/index.vue'),
            meta: {
                title: '充值中心'//冲刺比奇
            }
        },
        {
            path: '/dscj',
            name: 'Dscj',
            component: () => import('@/views/newPayDscj/index.vue'),
            meta: {
                title: '充值中心'//大圣裁决
            }
        },
        {
            path: '/fkjlb',
            name: 'Fkjlb',
            component: () => import('@/views/fkjlb/index.vue'),
            meta: {
                title: '充值中心'//疯狂俱乐部
            }
        },
        {
            path: '/darkxiyou/mid',
            name: 'Darkxiyou',
            component: () => import('@/views/newPayDarkxiyou/mid.vue'),
            meta: {
                title: '支付跳转'
            }
        },
        {
            path: '/darkxiyou/pay',
            name: 'Pay',
            component: () => import('@/views/newPayDarkxiyou/pay.vue'),
            meta: {
                title: '打金传说支付'
            }
        },
        {
            path: '/test',
            name: 'Test',
            component: () => import('@/views/test.vue'),
            meta: {
                title: 'Test'
            }
        }
        /*,
        {
            path: '/test2',
            name: 'Test2',
            component: () => import('@/views/test2.vue'),
            meta: {
                title: 'Test2'
            }
        }*/
    ]
})
