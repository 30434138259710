import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import './setFontSize'

import VueWechatTitle from 'vue-wechat-title';
Vue.use(VueWechatTitle)

//vant
import { Form } from 'vant';
import { Field } from 'vant';
import { Button } from 'vant';
import { Popup } from 'vant';
import { Picker } from 'vant';
import { Swipe, SwipeItem } from 'vant';
import { Tab, Tabs } from 'vant';
import { Cell, CellGroup } from 'vant';
import { Col, Row } from 'vant';
import { Divider } from 'vant';
import { NoticeBar } from 'vant';
import { Tag } from 'vant';
import { Image as VanImage } from 'vant';
import { Loading } from 'vant';
import { Icon } from 'vant';
import { Dialog } from 'vant';
import { RadioGroup, Radio } from 'vant';
import './assets/rxdldEg/iconfont.css';
import '@/styles/index.css' // global css

//  中英文切换
// import LanguageData from './lang/index'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
let currentLang = navigator.languages[0]
console.log("当前语言" + currentLang);
switch (currentLang) {
  case 'zh':
    break
  case 'zh-CN':
    currentLang = 'zh'
    break
  default:
    currentLang = 'us'
    break
}
const i18n = new VueI18n({
  locale: currentLang,
  fallbackLocale: 'zh',
  messages: {
    // 'zh': location.href = "https://wx.playhy.com/#/cn/rxdld",
    // 'us': location.href = "https://wx.playhy.com/#/eg/rxdld"
    // 'zh': LanguageData.ZH,
    // 'us': LanguageData.US
  }
})

Vue.use(Col);
Vue.use(Row);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Form);
Vue.use(Field);
Vue.use(Button);
Vue.use(Popup);
Vue.use(Picker);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Divider);
Vue.use(NoticeBar);
Vue.use(Tag)
Vue.use(VanImage);
Vue.use(Loading);
Vue.use(Icon);
Vue.use(Dialog);
Vue.use(RadioGroup);
Vue.use(Radio);

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  i18n,
  router
}).$mount('#app')
